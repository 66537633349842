<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<AppBoxTheme :options="options" @proceed="proceed"></AppBoxTheme>
	</div>
</template>

<script>
import AppBoxTheme from "@/components/ui/AppBoxTheme";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";
import { mapTypeSwitchingMixin } from "@/mixins/mapTypeSwitchingMixin";

export default {
	name: "ChooseTopicPage",

	mixins: [mapTypeSwitchingMixin],

	data() {
		return {
			options: [
				{
					title: "app.choose_topic.mind_map.standard.board.title",
					subtitle: "app.choose_topic.mind_map.standard.board.subtitle",
					icon: "mindmap",
					route: {
						name: "ChooseTopicStandardMindmap",
					},
				},
				{
					title: "app.choose_topic.mind_map.wizard.board.title",
					subtitle: "app.choose_topic.mind_map.wizard.board.subtitle",
					icon: "step",
					route: {
						name: "ChooseTopicWizardMindmap",
					},
					fade: this.$environments.environment == "production",
				},
				{
					title: "app.choose_topic.mind_map.table_chart.board.title",
					icon: "data-table",
					route: {
						name: "ChooseTopicTableChart",
					},
					fade: true,
				},
			],
		};
	},

	components: {
		AppBoxTheme,
		StageIntroDialog,
	},

	methods: {
		async proceed(route) {
			try {
				this.$loader.start();

				if (!route) {
					return;
				}

				// Reset current and completed steps if else priority route
				if (route.name != "ChooseTopicPrioritizationTool") {
					await this.resetSteps(route.name);
				}

				this.$router.push({ name: route.name });
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
