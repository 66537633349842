<template>
	<div v-if="show && intro" class="text-center">
		<v-dialog persistent v-model="dialog" width="700">
			<v-card class="pa-4">
				<v-card-text class="pt-3">
					<v-row>
						<v-col sm="10" class="text-h5 oflm-wr font-weight-black">
							<span>{{ intro.title }}</span>
							<span class="mx-5">
								<TextToSpeech
									slot="append"
									:text="intro.description"
								></TextToSpeech>
							</span>
						</v-col>
						<v-col v-if="isAdmin" class="text-end">
							<v-icon color="black" @click="dialog = false" size="30"
								>$vuetify.icons.values.close</v-icon
							>
						</v-col>
					</v-row>
				</v-card-text>

				<!-- <v-card-text v-html="intro.description"></v-card-text> -->

				<v-card-actions v-if="!isAdmin">
					<v-spacer></v-spacer>
					<AppButton
						label="app.start"
						color="greenAccent2"
						:icon="$vuetify.rtl ? 'left_chevron' : 'right_chevron'"
						@click="startProcess()"
					></AppButton>
				</v-card-actions>

				<v-card-text class="mt-3">
					<iframe
						:src="intro.video_link"
						title="SKILLSX"
						allow="autoplay; fullscreen"
						allowtransparency="true"
						frameborder="0"
						scrolling="no"
						class="wistia_embed"
						name="wistia_embed"
						msallowfullscreen
					></iframe>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";

export default {
	name: "StageIntroDialog",

	data() {
		return {
			moment,
			dialog: true,
			intro: null,
		};
	},

	components: {
		TextToSpeech,
	},

	created() {
		this.init();
	},

	computed: {
		...mapState({
			current_stage: (state) => state.research.current_stage,
			current_module: (state) => state.research.current_module,
		}),

		...mapGetters({
			isAdmin: "user/isAdmin",
		}),

		show() {
			return !this.plan || (this.plan && !this.plan.actual_start_date);
		},

		plan() {
			return this.$store.getters["planAndManage/getStagePlan"](
				this.current_stage,
			);
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				// Get user's plan
				await this.getUserPlan();
				// Get process status
				await this.getProcessStatus();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getProcessStatus() {
			if (this.current_module && this.current_stage) {
				this.intro = await this.$store.dispatch("process/getProcessStatus", {
					process: "M" + this.current_module,
					step: this.current_stage,
				});
			}
		},

		async getUserPlan() {
			await this.$store.dispatch("planAndManage/getUserPlan");
		},

		getYoutubeEmbedLink() {
			var url = this.intro.video_link;
			var youtubeId = url.substring(url.lastIndexOf("/") + 1);
			return "https://www.youtube.com/embed/" + youtubeId;
		},

		async startProcess() {
			try {
				this.$loader.start();

				// For first stage do not calculate.
				if (this.current_stage && this.current_stage != 11) {
					// Set the plan's actual start date
					this.plan.actual_start_date = moment().format();

					// Update plan at server
					await this.$store.dispatch("planAndManage/updateUserPlan", this.plan);
				}

				// Close the dialog
				this.dialog = false;
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
